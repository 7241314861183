import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  LinearProgress,
  Typography,
  Box,
  Grid,
  Button,
  Divider,
} from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import GridCardHeader from './GridCardHeader';

import IntentCustomizationPanel from './IntentCustomizationPanel';
import colors from './colors';

const { primary, white, loadingBlue, bubbleBlue } = colors;

const styles = {
  button: {
    backgroundColor: primary,
    color: white,
  },
  buttonIcon: {
    marginLeft: 6,
  },
  description: {
    marginBottom: 30,
    marginTop: 20,
  },
  setWrapper: {
    padding: '20px',
  },
  colorPrimary: {
    backgroundColor: loadingBlue,
  },
  barColorPrimary: {
    backgroundColor: bubbleBlue,
  }
};

const IntentCustomizationPanels = (props) => {
  const { intentSets, addDirtyIntent } = props;
  return intentSets.map((intentSet, index) => <IntentCustomizationPanel key={index} intentSet={intentSet} addDirtyIntent={addDirtyIntent} />);
};

class CreateProgress extends Component {
  constructor(props) {
    super(props);
    const inboundState = this.props.location.state;
    if (!inboundState || !inboundState.createRequest) this.props.history.push('/');
    this.state = {
      isLoading: true,
      progress: 0,
      mockTimeout: 5000,
      tickTimeout: 1000,
      ...inboundState,
      dirtyIntentSets: [],
      updaingIntentSets : false,
    };
    this.onNext = this.onNext.bind(this);
  }
  async componentDidMount() {
    const { mocks } = this.state;
    this.progressInterval = setInterval(() => this.tickProgress(), this.state.tickTimeout);
    if (mocks) {
      const { mockTimeout } = this.state;
      const Name = 'qs1587508209324';
      const Organization = 'vmware';
      setTimeout(() => {
        this.setState({
          bot: {
            Organization,
            Bot: { Name },
            Endpoint: { Address: `${Organization}-${Name}` },
            SBRegion : 'us-1'
          },
          status: 200,
          isLoading: false,
        });
      }, mockTimeout);
      return;
    }
    this.setState({ progress: 0, isLoading: true });
    const createResponse = await fetch(`${process.env.PUBLIC_URL}/CreateQuickstart`, {
      method: 'post',
      accept: 'application/json',
      body: JSON.stringify(this.state.createRequest),
      headers: { 'Content-Type': 'application/json' },
    });
    if (createResponse.status !== 200){
      return this.setState({ isLoading : false, status : createResponse.status, response:  await createResponse.json() })
    }
    const bot = await createResponse.json();
    const { srn } = bot;
    this.setState({ srn, bot });
    this.pollingInterval = setInterval(() => {
      this.pollPublishStatus();
    }, 5000);
  }
  async onNext() {
    await this.updateIntentSets();
    const { createRequest, stepsStore, bot } = this.state;
    this.props.history.push({
      pathname: '/bot',
      state: { bot, createRequest, stepsStore },
    });
  }
  
  async updateIntentSets(){
    const { dirtyIntentSets, bot, mocks } = this.state;
    if (dirtyIntentSets.length === 0){
      return;
    }
    this.setState({ updaingIntentSets : true })
    if (mocks){
      await new Promise(resolve => {
        setTimeout(resolve, 3000);
      })
      return this.setState({ updaingIntentSets : false });
    }
    const updateIntentsResponse = await fetch(`${process.env.PUBLIC_URL}/UpdateIntentSet`, {
      method: 'post',
      accept: 'application/json',
      body: JSON.stringify({ 
        Name: bot.Bot.Name,
        Intents : dirtyIntentSets
      }),
      headers: { 'Content-Type': 'application/json' },
    });
    this.setState({ updaingIntentSets : false })
    if (updateIntentsResponse.status !== 200){
      this.setState({ status : updateIntentsResponse.status })
      throw new Error(`Error updating intentsets ${updateIntentsResponse.status}`);
    }
    return;
  }
  async pollPublishStatus() {
    const { srn } = this.state;
    const publishStatusResponse = await fetch(`${process.env.PUBLIC_URL}/CheckPublishStatus`, {
      method: 'post',
      accept: 'application/json',
      body: JSON.stringify({ srn }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (publishStatusResponse.status !== 200) {
      clearInterval(this.pollingInterval);
      throw new Error(`Error checking publish status ${publishStatusResponse.status}`);
    }
    const response = await publishStatusResponse.json();
    if (response.status === 'RUNNING') return;
    clearInterval(this.pollingInterval);
    if (response.status === 'FAILED'){
      this.setState({ progress: 100, isLoading: false, status  : 500, response : { "error" : "Your bot model failed to train" } });
    }
    this.setState({ progress: 100, isLoading: false });
  }
  tickProgress() {
    const { mocks, mockTimeout, tickTimeout } = this.state;
    let { progress } = this.state;
    // One less than needed so we actually display 100% at some point
    if (mocks) progress += (100 / ((mockTimeout - tickTimeout) / tickTimeout));
    else progress += 1;
    progress = Math.min(100, progress);
    this.setState({ progress });
    if (progress === 100) clearInterval(this.progressInterval);
  }
  render() {
    const { classes } = this.props;
    const {
      progress, isLoading, response, status, createRequest, updaingIntentSets,
    } = this.state;
    const nextText = (updaingIntentSets) ? 'Updating...' : 'Next';
    const addDirtyIntent = (intentSet) => {
      const filtered = this.state.dirtyIntentSets.filter(_set => _set.Alias !== intentSet.Alias);
      filtered.push(intentSet);
      this.setState({ dirtyIntentSets: [...filtered] });
    };
    if (status && status !== 200) {
      return (
        <span>
          <Typography variant="h6" gutterBottom>
            Your bot has failed to create
          </Typography>
          Here is some information as to why:
          <Box fontFamily="Monospace">
            { JSON.stringify(response) }
          </Box>
        </span>
      );
    }
    return (
      <>
        { !isLoading &&
          (
            <>
              <Grid item xs={12} className={classes.setWrapper}>
                <GridCardHeader title="Success" icon="thumb_up" />
                <Typography variant="body1" gutterBottom classes={{ root: this.props.classes.description }}>
                  Your bot has been created!
                </Typography>
              </Grid>
            </>)
        }
        { isLoading &&
          (
            <>
              <Grid item xs={12} className={classes.setWrapper}>
                <GridCardHeader title="Creating Bot...." icon="hourglass_empty_icon" />
                <Typography variant="body1" gutterBottom classes={{ root: this.props.classes.description }}>
                  We are creating your bot. While you wait, here are some things you can customize.
                </Typography>
                <LinearProgress variant="determinate" value={progress} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}} />
                <Typography align="left" variant="subtitle2" gutterBottom classes={{ root: this.props.classes.description }}>
                  Progress
                </Typography>
              </Grid>
            </>)
        }
        <Divider />
        {createRequest.IntentSets && 
        <Grid item xs={12} className={classes.setWrapper}>
          <GridCardHeader title="Customize your Bot Responses" icon="settings_icon" />
          <Typography variant="body1" gutterBottom classes={{ root: this.props.classes.description }}>
          We have provided some default responses, but you may want to customize these to the specifics of your business.
          </Typography>
        </Grid>
        }
        <Grid item xs={12} className={classes.setWrapper}>
          <IntentCustomizationPanels intentSets={createRequest.IntentSets} addDirtyIntent={addDirtyIntent} />
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <Button disabled={ isLoading || updaingIntentSets } className={classes.button} disableElevation variant="contained" onClick={this.onNext}>
              { nextText } <ArrowForward className={classes.buttonIcon} />
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withRouter(withStyles(styles)(CreateProgress));
